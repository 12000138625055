import React from 'react'
import Particles from 'react-particles-js';

import TextLoop from 'react-text-loop'

import ParticlesData from '../../data/particles.json' ;
import Scroll from 'react-scroll'


import { language } from '../hooks/language'

const ScrollLink = Scroll.Link


const Header = ({idioma}) => {

    const text = ["BackEnd", "FrontEnd", "FullStack", "Data Analyst", "Game Developer"]
    return (
        <header className="App-header" id="header">
            <h1>Sebastián Cristi <span><TextLoop children={text} springConfig={{ stiffness: 180, damping: 8 }}/></span>

                                <ScrollLink to="about" smooth={true} duration={500} className="header-button">{language(idioma, "header.about_me_button")}</ScrollLink>
            </h1>

            <Particles
                width="100%"
                height="100vh"
                params={ParticlesData}
            >

            </Particles>
        </header>
    )
}

export default Header;