import React from 'react';
import styled from 'styled-components';
import Scroll from 'react-scroll'
import { language } from '../../hooks/language'
const ScrollLink = Scroll.Link


const Button = styled.a`

border: 2px solid #fff;
    border-top-color: rgb(255, 255, 255);
    border-right-color: rgb(255, 255, 255);
    border-bottom-color: rgb(255, 255, 255);
    border-left-color: rgb(255, 255, 255);
border-top-color: rgb(255, 255, 255);
border-right-color: rgb(255, 255, 255);
border-bottom-color: rgb(255, 255, 255);
border-left-color: rgb(255, 255, 255);
box-sizing: inherit;
cursor: pointer;
font-size: 13pt;
padding: 5px 10px;


`

const Ul = styled.ul`
  list-style: none;
  display: flex;
  flex-flow: row nowrap;

  .link {
    cursor: pointer;
  }

  li {
    padding: 18px 10px;

  }

  margin-left: auto;
  margin-right: auto;

  color: white;

  @media (max-width: 768px) {
    flex-flow: column nowrap;
    background-color: #0D2538;
    position: fixed;
    transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(100%)'};
    top: 0;
    right: 0;
    height: 100vh;
    width: 300px;
    padding-top: 3.5rem;
    transition: transform 0.3s ease-in-out;
    li {
      color: #fff;

    }
  }

  .active_link {
    color: #c80000;
    transition: all 0.5s;
  }

`;

const RightNav = ({ open, setOpen, idioma, setIdioma }) => {
  return (
    <Ul open={open}>
      <li>
        <ScrollLink
            className="link"
            activeClass="active_link"
            to="header"
            spy={true}
            smooth={true}
            duration={500}
            onClick={() => setOpen(!open)}
            >{language(idioma, "header.nav_inicio")}
          </ScrollLink>
        </li>
      <li>
        <ScrollLink
            className="link"
            activeClass="active_link"
            to="about"
            spy={true}
            smooth={true}
            duration={500}
            onClick={() => setOpen(!open)}
            >{language(idioma, "header.nav_sobre_mi")}
          </ScrollLink>
        </li>
        <li>
          <ScrollLink
            className="link"
            activeClass="active_link"
            to="portafolio"
            spy={true}
            smooth={true}
            duration={500}
            onClick={() => setOpen(!open)}
            >{language(idioma, "header.nav_portafolio")}
          </ScrollLink>
        </li>

        <li>
          <ScrollLink
            className="link"
            activeClass="active_link"
            to="contacto"
            spy={true}
            smooth={true}
            duration={500}
            onClick={() => setOpen(!open)}
            >{language(idioma, "header.nav_contactame")}
          </ScrollLink>
        </li>
        <div className="social_icons">
          <li><a href="https://stackoverflow.com/users/7983131/sebastian-cristi-castillo?tab=profile"><i className="fa fa-stack-overflow"></i></a></li>
          <li><a href="https://github.com/GrayJacketStudios/"><i className="fa fa-github"></i></a></li>
          <li><a href="https://www.linkedin.com/in/scristicastillo/"><i className="fa fa-linkedin"></i></a></li>
          <li><a href="https://twitter.com/SebaCDev"><i className="fa fa-twitter"></i></a></li>
        </div>
        {idioma === "es" && <li><Button onClick={() => setIdioma("en")}>English</Button></li>}
        {idioma !== "es" && <li><Button onClick={() => setIdioma("es")}>Español</Button></li>}

    </Ul>
  )
}

export default RightNav