import React from 'react'

export default function Item(props) {
    return (
        <div style={{margin: "10px", maxWidth: "80px", maxHeight: "80px", display: "flex", justifyContent: "center", alignItems: "center"}}>
            <div style={{margin: "auto"}}>
                <div style={{width: "90px", height: "90px"}}>
                    <img src={props.imagen} alt={props.children} style={{position: "relative", top: "50%", transform: "translateY(-50%)", maxHeight: "65px", maxWidth: "90px"}}/>
                </div>
                <center>
                    <p style={{textAlign: "center"}}> {props.children}</p>
                </center>
            </div>
        </div>
    )
}
