import React from 'react'
import Section from '../components/layout/Section'
import { language } from '../components/hooks/language'
import '../styles/portafolio.css'

export default function Portafolio({idioma}) {
    return (
        <Section id="portafolio">
            <h1>{language(idioma, "portafolio.title")}</h1>
            <h2>{language(idioma, "portafolio.title-pages")}</h2>
            <div style={{marginTop: "10px",display: "grid", gridTemplateRows: "min-content", gridTemplateColumns: "repeat(auto-fill, minmax(250px, 390px))", gridRowGap: "5px"}}>
                <PItem
                    imagen="/img/paginas/hostalkeikruk/inicio.png"
                    url="https://hostalkeikruk.cl"
                    nombre="www.hostalkeikruk.cl"
                    ><ul><li>Laravel</li></ul></PItem>
                    <PItem
                    imagen="/img/paginas/laconsulta/inicio.png"
                    url="https://laconsulta.org"
                    nombre="www.laconsulta.org"
                    ><ul><li>Laravel</li></ul></PItem>
                    <PItem
                    imagen="/img/paginas/chilenglish/inicio.png"
                    url="https://chilenglish.cl"
                    nombre="www.chilenglish.cl"
                    ><ul><li>Wordpress</li></ul></PItem>
                    <PItem
                    imagen="/img/paginas/hostalkeikrukSAI/SAI-Calendar.PNG"
                    nombre="Pag. Administrativa Hostal Keikruk"
                    ><ul><li>Laravel</li><li>Javascript</li></ul></PItem>

                    <PItem
                    imagen="/img/paginas/TaxCalculator/inicio.png"
                    url="https://grayjacketstudios.github.io/taxCalculator/"
                    nombre="Calculadora de IVA"
                    ><ul><li>ReactJS</li></ul></PItem>

                    <PItem
                    imagen="/img/paginas/fichas-ewok/inicio.png"
                    url="https://grayjacketstudios.github.io/fichas-ewok/"
                    nombre="Fichas de rol"
                    ><ul><li>ReactJS</li></ul></PItem>
            </div>

            <h2>{language(idioma, "portafolio.applications")}</h2>
            <div style={{marginTop: "10px",display: "grid", gridTemplateRows: "min-content", gridTemplateColumns: "repeat(auto-fill, minmax(250px, 390px))", gridRowGap: "5px"}}>
                <PItem
                imagen="/img/programas/POSCafeKeikruk/programa.png"
                nombre="Sistema POS cafe Keikruk">
                    <ul>
                        <li>NodeJS</li>
                        <li>ReactJS</li>
                        <li>Redux</li>
                        <li>Electron</li>
                    </ul>
                </PItem>
                <PItem
                imagen="/img/programas/forMusic/captura.png"
                url="https://github.com/GrayJacketStudios/ForMusic/"
                nombre="ForMusic">
                    <ul>
                        <li>Python 3.7</li>
                        <li>PyGT5</li>
                    </ul>
                </PItem>

                <PItem
                imagen="/img/programas/harp/captura.png"
                nombre="HARP">
                    {language(idioma, "portafolio.harp-text")}
                    <ul>
                        <li>Python 3.7</li>
                        <li>PyGT5</li>
                    </ul>
                </PItem>



            </div>


        <h2>{language(idioma, "portafolio.title-packages")}</h2>
        <div style={{marginTop: "10px",display: "grid", gridTemplateRows: "min-content", gridTemplateColumns: "repeat(auto-fill, minmax(250px, 390px))", gridRowGap: "5px"}}>
            <PItem
                imagen="/img/npm.png"
                url="https://www.npmjs.com/package/verificador-rut"
                nombre="Verificador-rut">
                    {language(idioma, "portafolio.verificador-rut-text")}
                    <ul>
                        <li>NodeJS</li>
                        <li>ReactJS</li>
                    </ul>
                </PItem>
                <PItem
                imagen="/img/npm.png"
                url="https://www.npmjs.com/package/json-translation"
                nombre="json-translation">
                    {language(idioma, "portafolio.json-translation")}
                    <ul>
                        <li>NodeJS</li>
                    </ul>
                </PItem>
        </div>

        </Section>
    )
}



const PItem = (props) => {

    return (
        <div className="container">
            <img src={props.imagen} style={{height: "250px", width: "385px"}} className="image" alt={props.nombre}/>
            <div className="middle">
            <div className="text"><a href={props.url} target="_blank" rel="noopener noreferrer">{props.nombre}</a></div>
            {props.children}
            </div>
        </div>
    )
}