import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Burger from './Burger';
import "./extras.css"

const Nav = styled.nav`
  z-index: 100;
  width: 100%;
  height: 55px;
  border-bottom: 3px solid #c80000;
  padding: 0 0px;
    display: flex;
  justify-content: space-between;
  margin-top: -5px;
  background-color: #16181c;
  @media (max-width: 768px) {

    height: 0px;
    border-bottom: 0px;
  }
`


const Navbar = ({idioma, setIdioma}) => {
    const [isFixed, setFixed] = useState("no-fixed")

    useEffect(() => {
        window.addEventListener('scroll', listenToScroll)
    }, [])

    const listenToScroll = () => {
        const winScroll =
    document.body.scrollTop || document.documentElement.scrollTop

    if (winScroll >= document.documentElement.clientHeight)setFixed("fixed")
    else setFixed("no-fixed")
    }

  return (
    <>

    <Nav className={isFixed}>
      <Burger isFixed={isFixed} idioma={idioma} setIdioma={setIdioma}/>
    </Nav>
    <div className={`spacebar ${isFixed}`}></div>
    </>
  )
}

export default Navbar