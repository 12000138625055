import React from 'react'
import styled from 'styled-components';



const FooterDiv = styled.div`
    background-color: #16181c;
    color: white;
    min-height: 10vh;
    margin-top: 30px;
    padding-top: 15px;


`

export default function Footer() {
    return (
        <FooterDiv>
            www.scristi.com © {new Date().getFullYear()}<br />
        </FooterDiv>
    )
}
