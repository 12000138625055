import React from 'react'
import Section from '../components/layout/Section'
import Item from '../components/Item'
import { language } from '../components/hooks/language'


export default function About({idioma}) {
    return (
        <Section id="about">
            <br /><br /><br />
            <h1>{language(idioma, "about.title")}</h1>
            <br />
            <div style={{display: "inline-grid", gridColumnGap: "10px"}} >
                <div  className="grid-perfil-item" title="Sebastián Cristi">
                    <svg viewBox="0 0 100 100" className="svg-perfil" align="left">
                        <defs>
                            <pattern id="img" patternUnits="userSpaceOnUse" width="100" height="100">
                            <image href="/img/perfil.jpeg" x="-25" width="150" height="100" className="perfil" />
                            </pattern>
                        </defs>
                        <polygon points="50 1 95 25 95 75 50 99 5 75 5 25" fill="url(#img)"/>
                    </svg>
                </div>
                <div className="grid-perfil-item" style={{marginTop: "10px"}}>
                    <p>{language(idioma, "about.first")}</p>
                    <br></br>
                    <p>{language(idioma, "about.second")}</p>
                    <br></br>
                    <p>{language(idioma, "about.third")}<a href="https://www.unab.cl/">universidad Andres Bello</a> e <a href="https://www.inacap.cl/">Inacap</a>.</p>
                    </div>

            </div>
            <br></br>
            <h2>{language(idioma, "about.conocimiento")}</h2>
            <br />
            <h3>{language(idioma, "about.languages")}</h3>
            <div style={{display: "grid", gridTemplateRows: "min-content", gridTemplateColumns: "repeat(auto-fill, minmax(100px, 1fr))", gridRowGap: "35px", gridColumnGap: "35px"}}>
                <Item imagen="/logos/php.png">PHP</Item>
                <Item imagen="/logos/python.png">Python</Item>
                <Item imagen="/logos/C-Programming.png">C</Item>
                <Item imagen="/logos/c.png">C++</Item>
                <Item imagen="/logos/javascript.png">Javascript</Item>
                <Item imagen="/logos/java.png">Java</Item>
                <Item imagen="/logos/sql.png">SQL</Item>
                <Item imagen="/logos/git.png">GIT</Item>
            </div>

            <br></br><br></br><br></br>
            <h3>Frameworks</h3>
            <div style={{display: "grid", gridTemplateRows: "min-content", gridTemplateColumns: "repeat(auto-fill, minmax(100px, 1fr))", gridRowGap: "35px", gridColumnGap: "35px"}}>
                <Item imagen="/logos/laravel.png">Laravel</Item>
                <Item imagen="/logos/nodejs.png">Node.js</Item>
                <Item imagen="/logos/reactjs.png">Reactjs</Item>
                <Item imagen="/logos/nextjs.png">Nextjs</Item>
                <Item imagen="/logos/redux.png">redux</Item>
                <Item imagen="/logos/electron.png">Electronjs</Item>
                <Item imagen="/logos/WordPress.png">WordPress</Item>
                <Item imagen="/logos/bootstrap.png">Bootstrap</Item>
                <Item imagen="/logos/materialUI.png">materialUI</Item>
            </div>


            <br></br><br></br><br></br>
            <h3>{language(idioma, "about.tecnologias")}</h3>
            <div style={{display: "grid", gridTemplateRows: "min-content", gridTemplateColumns: "repeat(auto-fill, minmax(100px, 1fr))", gridColumnGap: "35px"}}>
                <Item imagen="/logos/docker.png">Docker</Item>
                <Item imagen="/logos/github.png">github</Item>
                <Item imagen="/logos/Unity.png">Unity3D</Item>
                <Item imagen="/logos/Godot.svg">GodotEngine</Item>
                <Item imagen="/logos/mongoDB.png">mongoDB</Item>
                <Item imagen="/logos/MySql.png">MySQL</Item>
                <Item imagen="/logos/mariadb.png">MariaDB</Item>
                <Item imagen="/logos/oracle.png">Oracle Database</Item>

            </div>
        </Section>
    )
}
