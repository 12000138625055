import React from 'react'
import styled from 'styled-components';

const Div = styled.div`

    max-width: 780px;
    margin: auto;
    margin-top: 10px;

    @media (max-width: 768px) {
        max-width: 90%;
    }

`;



export default function Section(props) {
    return (
        <Div>
            <div id={props.id} className="section">
                {props.children}
            </div>

        </Div>
    )
}
