import ingles from '../../data/ingles.json'
import español from '../../data/español.json'
// Componente para la muestra de textos segun el idioma.
export function language(idioma, texto) {

    let parsed = texto.split(".")
    let temp = (idioma === "es")?español:ingles
    parsed.map((item) => {
        temp = temp[item]
        return null
    })
    return temp
}
