import React, { useEffect, useState } from 'react';
import './App.css';

import Navbar from './components/layout/nav/Navbar'


import Header from './components/layout/Header'
import About from './pages/About';
import Footer from './components/layout/Footer';
import Portafolio from './pages/Portafolio';
import Contacto from './pages/Contacto';



function App() {
  const [idioma, setIdioma] = useState("")
  useEffect(() => {
    let locale =  (navigator.languages && navigator.languages.length) ? navigator.languages[0] : navigator.language;
    let temp = locale.split("-")
    setIdioma(temp[0])
  }, [])

  return (
    <div className="App">
        <Header idioma={idioma}/>
        <Navbar idioma={idioma} setIdioma={setIdioma}/>
        <About idioma={idioma}/>
        <Portafolio idioma={idioma}/>
        <Contacto idioma={idioma}/>
        <Footer />

    </div>
  );
}

export default App;
