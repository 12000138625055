import React, { useState } from 'react'
import Section from '../components/layout/Section'
import emailjs from 'emailjs-com'
import { language } from '../components/hooks/language'
import '../styles/contacto.css'

export default function Contacto({idioma}) {
    const [form, setForm] = useState({
        nombre: "",
        email: "",
        telefono: "",
        mensaje: ""
    })


    const [estado, setEstado] = useState("")

    const handleSubmit = (e) => {
        e.preventDefault();

        setEstado(language(idioma, "contacto.status_msg_sending"))
        emailjs.init("user_YDzjVYZXXTKUICPVIkxvZ")
        emailjs.send("la_consulta", "template_0GsAVMDC", form   ).then((result) => {
            setEstado(language(idioma, "contacto.status_msg_send"))
        }, (error) => {
            setEstado(language(idioma, "contacto.status_msg_error"))
        });


    }


    return (
        <div>
            <Section id="contacto">
            <h1>{language(idioma, "contacto.title")}</h1>
            <p>{language(idioma, "contacto.sub_title")}<a href="mailto:contacto@scristi.com">contacto@scristi.com</a></p>

            <form onSubmit={handleSubmit}>
                <label>{language(idioma, "contacto.nombre")} *</label>
                <input type="text" placeholder={language(idioma, "contacto.nombre")} value={form.nombre} onChange={(e) => setForm({...form, nombre: e.target.value})} required/>
                <label>{language(idioma, "contacto.correo")} *</label>
                <input type="email" placeholder={language(idioma, "contacto.correo")} value={form.email} onChange={(e) => setForm({...form, email: e.target.value})} required/>
                <label>{language(idioma, "contacto.telefono")}</label>
                <input type="text" placeholder={language(idioma, "contacto.telefono")} value={form.telefono} onChange={(e) => setForm({...form, telefono: e.target.value})}/>
                <label htmlFor="subject">{language(idioma, "contacto.mensaje")} *</label>
                <textarea id="subject" name="subject" placeholder={language(idioma, "contacto.mensaje")} value={form.mensaje} onChange={(e) => setForm({...form, mensaje: e.target.value})} required/>

                <input type="submit" value={language(idioma, "contacto.btn_enviar")} disabled={(estado === language(idioma, "contacto.status_msg_sending"))?true:false}/> {estado}
            </form>
            </Section>
        </div>
    )
}
